import React from "react";
import "./Discord.css";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ButtonLandpage() {
  return (
    <div className="ButtonLandpage">
      <button
        type="button"
        className="btn button-discord"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal-one"
      >
        Join our Discord <FontAwesomeIcon icon={faDiscord} />
      </button>

      <div
        className="modal fade"
        id="exampleModal-one"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel-one"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabelO-one">
                ⚠️ Dear Community
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {" "}
              <p>
                Due to safety reasons and past incidents,{" "}
                <b>
                  we only open our Discord to chosen members like Holders and
                  Whitelisters to avoid scams!
                </b>
                🔐{" "}
              </p>
              <p>
                Many projects have lately been heavily scammed, which is why
                we'd like to to intervene prematurely to ensure everyone's
                safety!
              </p>
              <p>
                We hope you understand since we want to make sure the experience
                at Certified Veggies is 100% safe for everyone 👍
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                data-bs-dismiss="modal"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
