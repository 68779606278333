import React from "react";
import "./SvgBackground.css";

export default function SvgBackground() {
  return (
    <div className="SvgBackground">
      {" "}
      <svg version="1.1" viewBox="0 0 1422 800" className="background-svg">
        <defs>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="oooscillate-grad"
          >
            <stop
              stopColor="hsl(43, 98%, 60%)"
              stopOpacity="1"
              offset="0%"
            ></stop>
            <stop
              stopColor="hsl(50, 98%, 80%)"
              stopOpacity="1"
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <g
          strokeWidth="1"
          stroke="url(#oooscillate-grad)"
          fill="none"
          strokeLinecap="round"
        >
          <path
            d="M 0 1216 Q 355.5 -100 711 400 Q 1066.5 900 1422 1216"
            opacity="0.61"
          ></path>
          <path
            d="M 0 1178 Q 355.5 -100 711 400 Q 1066.5 900 1422 1178"
            opacity="0.74"
          ></path>
          <path
            d="M 0 1140 Q 355.5 -100 711 400 Q 1066.5 900 1422 1140"
            opacity="0.64"
          ></path>
          <path
            d="M 0 1102 Q 355.5 -100 711 400 Q 1066.5 900 1422 1102"
            opacity="0.54"
          ></path>
          <path
            d="M 0 1064 Q 355.5 -100 711 400 Q 1066.5 900 1422 1064"
            opacity="0.98"
          ></path>
          <path
            d="M 0 1026 Q 355.5 -100 711 400 Q 1066.5 900 1422 1026"
            opacity="0.85"
          ></path>
          <path
            d="M 0 988 Q 355.5 -100 711 400 Q 1066.5 900 1422 988"
            opacity="0.81"
          ></path>
          <path
            d="M 0 950 Q 355.5 -100 711 400 Q 1066.5 900 1422 950"
            opacity="0.97"
          ></path>
          <path
            d="M 0 912 Q 355.5 -100 711 400 Q 1066.5 900 1422 912"
            opacity="0.28"
          ></path>
          <path
            d="M 0 874 Q 355.5 -100 711 400 Q 1066.5 900 1422 874"
            opacity="0.89"
          ></path>
          <path
            d="M 0 836 Q 355.5 -100 711 400 Q 1066.5 900 1422 836"
            opacity="0.48"
          ></path>
          <path
            d="M 0 798 Q 355.5 -100 711 400 Q 1066.5 900 1422 798"
            opacity="0.72"
          ></path>
          <path
            d="M 0 760 Q 355.5 -100 711 400 Q 1066.5 900 1422 760"
            opacity="0.34"
          ></path>
          <path
            d="M 0 722 Q 355.5 -100 711 400 Q 1066.5 900 1422 722"
            opacity="0.38"
          ></path>
          <path
            d="M 0 684 Q 355.5 -100 711 400 Q 1066.5 900 1422 684"
            opacity="0.08"
          ></path>
          <path
            d="M 0 646 Q 355.5 -100 711 400 Q 1066.5 900 1422 646"
            opacity="0.52"
          ></path>
          <path
            d="M 0 608 Q 355.5 -100 711 400 Q 1066.5 900 1422 608"
            opacity="0.71"
          ></path>
          <path
            d="M 0 570 Q 355.5 -100 711 400 Q 1066.5 900 1422 570"
            opacity="0.29"
          ></path>
          <path
            d="M 0 532 Q 355.5 -100 711 400 Q 1066.5 900 1422 532"
            opacity="0.55"
          ></path>
          <path
            d="M 0 494 Q 355.5 -100 711 400 Q 1066.5 900 1422 494"
            opacity="0.89"
          ></path>
          <path
            d="M 0 456 Q 355.5 -100 711 400 Q 1066.5 900 1422 456"
            opacity="0.62"
          ></path>
          <path
            d="M 0 418 Q 355.5 -100 711 400 Q 1066.5 900 1422 418"
            opacity="0.93"
          ></path>
          <path
            d="M 0 380 Q 355.5 -100 711 400 Q 1066.5 900 1422 380"
            opacity="0.61"
          ></path>
          <path
            d="M 0 342 Q 355.5 -100 711 400 Q 1066.5 900 1422 342"
            opacity="0.37"
          ></path>
          <path
            d="M 0 304 Q 355.5 -100 711 400 Q 1066.5 900 1422 304"
            opacity="0.44"
          ></path>
          <path
            d="M 0 266 Q 355.5 -100 711 400 Q 1066.5 900 1422 266"
            opacity="0.92"
          ></path>
          <path
            d="M 0 228 Q 355.5 -100 711 400 Q 1066.5 900 1422 228"
            opacity="0.51"
          ></path>
          <path
            d="M 0 190 Q 355.5 -100 711 400 Q 1066.5 900 1422 190"
            opacity="0.40"
          ></path>
          <path
            d="M 0 152 Q 355.5 -100 711 400 Q 1066.5 900 1422 152"
            opacity="0.22"
          ></path>
          <path
            d="M 0 114 Q 355.5 -100 711 400 Q 1066.5 900 1422 114"
            opacity="1.00"
          ></path>
          <path
            d="M 0 76 Q 355.5 -100 711 400 Q 1066.5 900 1422 76"
            opacity="0.27"
          ></path>
        </g>
      </svg>{" "}
      <svg version="1.1" viewBox="0 0 1422 800" className="background-svg-two">
        <defs>
          <linearGradient
            x1="50%"
            y1="0%"
            x2="50%"
            y2="100%"
            id="oooscillate-grad"
          >
            <stop
              stopColor="hsl(43, 98%, 60%)"
              stopOpacity="1"
              offset="0%"
            ></stop>
            <stop
              stopColor="hsl(50, 98%, 80%)"
              stopOpacity="1"
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <g
          strokeWidth="1"
          stroke="url(#oooscillate-grad)"
          fill="none"
          strokeLinecap="round"
        >
          <path
            d="M 0 1216 Q 355.5 -100 711 400 Q 1066.5 900 1422 1216"
            opacity="0.61"
          ></path>
          <path
            d="M 0 1178 Q 355.5 -100 711 400 Q 1066.5 900 1422 1178"
            opacity="0.74"
          ></path>
          <path
            d="M 0 1140 Q 355.5 -100 711 400 Q 1066.5 900 1422 1140"
            opacity="0.64"
          ></path>
          <path
            d="M 0 1102 Q 355.5 -100 711 400 Q 1066.5 900 1422 1102"
            opacity="0.54"
          ></path>
          <path
            d="M 0 1064 Q 355.5 -100 711 400 Q 1066.5 900 1422 1064"
            opacity="0.98"
          ></path>
          <path
            d="M 0 1026 Q 355.5 -100 711 400 Q 1066.5 900 1422 1026"
            opacity="0.85"
          ></path>
          <path
            d="M 0 988 Q 355.5 -100 711 400 Q 1066.5 900 1422 988"
            opacity="0.81"
          ></path>
          <path
            d="M 0 950 Q 355.5 -100 711 400 Q 1066.5 900 1422 950"
            opacity="0.97"
          ></path>
          <path
            d="M 0 912 Q 355.5 -100 711 400 Q 1066.5 900 1422 912"
            opacity="0.28"
          ></path>
          <path
            d="M 0 874 Q 355.5 -100 711 400 Q 1066.5 900 1422 874"
            opacity="0.89"
          ></path>
          <path
            d="M 0 836 Q 355.5 -100 711 400 Q 1066.5 900 1422 836"
            opacity="0.48"
          ></path>
          <path
            d="M 0 798 Q 355.5 -100 711 400 Q 1066.5 900 1422 798"
            opacity="0.72"
          ></path>
          <path
            d="M 0 760 Q 355.5 -100 711 400 Q 1066.5 900 1422 760"
            opacity="0.34"
          ></path>
          <path
            d="M 0 722 Q 355.5 -100 711 400 Q 1066.5 900 1422 722"
            opacity="0.38"
          ></path>
          <path
            d="M 0 684 Q 355.5 -100 711 400 Q 1066.5 900 1422 684"
            opacity="0.08"
          ></path>
          <path
            d="M 0 646 Q 355.5 -100 711 400 Q 1066.5 900 1422 646"
            opacity="0.52"
          ></path>
          <path
            d="M 0 608 Q 355.5 -100 711 400 Q 1066.5 900 1422 608"
            opacity="0.71"
          ></path>
          <path
            d="M 0 570 Q 355.5 -100 711 400 Q 1066.5 900 1422 570"
            opacity="0.29"
          ></path>
          <path
            d="M 0 532 Q 355.5 -100 711 400 Q 1066.5 900 1422 532"
            opacity="0.55"
          ></path>
          <path
            d="M 0 494 Q 355.5 -100 711 400 Q 1066.5 900 1422 494"
            opacity="0.89"
          ></path>
          <path
            d="M 0 456 Q 355.5 -100 711 400 Q 1066.5 900 1422 456"
            opacity="0.62"
          ></path>
          <path
            d="M 0 418 Q 355.5 -100 711 400 Q 1066.5 900 1422 418"
            opacity="0.93"
          ></path>
          <path
            d="M 0 380 Q 355.5 -100 711 400 Q 1066.5 900 1422 380"
            opacity="0.61"
          ></path>
          <path
            d="M 0 342 Q 355.5 -100 711 400 Q 1066.5 900 1422 342"
            opacity="0.37"
          ></path>
          <path
            d="M 0 304 Q 355.5 -100 711 400 Q 1066.5 900 1422 304"
            opacity="0.44"
          ></path>
          <path
            d="M 0 266 Q 355.5 -100 711 400 Q 1066.5 900 1422 266"
            opacity="0.92"
          ></path>
          <path
            d="M 0 228 Q 355.5 -100 711 400 Q 1066.5 900 1422 228"
            opacity="0.51"
          ></path>
          <path
            d="M 0 190 Q 355.5 -100 711 400 Q 1066.5 900 1422 190"
            opacity="0.40"
          ></path>
          <path
            d="M 0 152 Q 355.5 -100 711 400 Q 1066.5 900 1422 152"
            opacity="0.22"
          ></path>
          <path
            d="M 0 114 Q 355.5 -100 711 400 Q 1066.5 900 1422 114"
            opacity="1.00"
          ></path>
          <path
            d="M 0 76 Q 355.5 -100 711 400 Q 1066.5 900 1422 76"
            opacity="0.27"
          ></path>
        </g>
      </svg>{" "}
    </div>
  );
}
