import React from "react";
import GifLogo from "./GifLogo.MP4";
import "./CoreTeam.css";

export default function CoreTeam() {
  return (
    <div className="CoreTeam">
      <div className="CoreTeamMobile">
        <div className="container-fluid">
          <div className="row">
            <div className="col-10 padding-title-coreteam">
              <h2 className="core-team-title">Core Team</h2>
              <h5 className="description-coreteam" align="left">
                Certified Veggies Collaborators
              </h5>
            </div>
            <div className="col-2">
              <h2 className="roadmap-number">04</h2>
            </div>
            <div className="row ">
              <div
                className="col-12 border-coreteam  section-coreteam"
                align="left"
              >
                <div className="row">
                  <div className="col-2 coreteam-item">
                    <p>001</p>
                  </div>
                  <div className="col-3 coreteam-item">
                    <p>Name</p>
                  </div>
                  <div className="col-4 coreteam-item">
                    <p>Section</p>
                  </div>
                  <div className="col-3 coreteam-item" align="right">
                    <p>Country</p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 border-coreteam-next  section-coreteam-next"
                align="left"
              >
                <div className="row">
                  <div className="col-2 coreteam-item">
                    <p>002</p>
                  </div>
                  <div className="col-3 coreteam-item">
                    <p>Name</p>
                  </div>
                  <div className="col-4 coreteam-item">
                    <p>Section</p>
                  </div>
                  <div className="col-3 coreteam-item" align="right">
                    <p>Country</p>
                  </div>
                </div>
              </div>
              <div
                className="col-12 border-coreteam-next  section-coreteam-next"
                align="left"
              >
                <div className="row">
                  <div className="col-2 coreteam-item">
                    <p>003</p>
                  </div>
                  <div className="col-3 coreteam-item">
                    <p>Name</p>
                  </div>
                  <div className="col-4 coreteam-item">
                    <p>Section</p>
                  </div>
                  <div
                    className="col-3 coreteam-item"
                    align="right" id="faq"
                  >
                    <p>Country</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <video className="videoTag-coreteam" autoPlay loop muted width={1000}>
            <source src={GifLogo} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  );
}
