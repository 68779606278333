import React, { useEffect } from "react";
import "./QuestionsMobile.css";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function QuestionsMobile() {
  const container = {
    hidden: { opacity: 0, scale: 1 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 1,
        staggerChildren: 0.8,
      },
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const nftTwo = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const nftThree = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: -10,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      className="QuestionsMobile"
      ref={ref}
      variants={container}
      initial="hidden"
      animate={controls}
      id="faq-mobile"
    >
      <div className="container-fluid-questions">
        {" "}
        <div className="row">
          {" "}
          <motion.div variants={nft} className="col-12" align="center">
            <h2 className="questions-title">Faq</h2>
            <h5 className="description-questions" align="center">
              Frequently Asked Questions
            </h5>
          </motion.div>
          <div className="accordion" id="accordionExample" align="left">
            <div className="accordion-item background-questions">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button button-questions"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  <span className="number-button">01</span> What are NFTs?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    {" "}
                    NFTs also known as non-fungible tokens are smart contracts
                    that represent the ownership of unique items (digital art,
                    music, videos, etc.) – a smart contract is therefore nothing
                    else than a program that is stored on a blockchain that runs
                    when predetermined conditions are met.
                  </p>{" "}
                  <p>
                    Non-fungible in this context means that it is unique and
                    irreplaceable. Moreover, a non-fungible item is not
                    interchangeable with other items because of its uniqueness.
                    NFTs only have one official owner, and the transaction and
                    contract are secured and recorded on the Ethereum
                    blockchain.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item background-questions">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button button-questions collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <span className="number-button">02</span> What is a
                  Blockchain?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    Blockchain is a system of recording information in a way
                    that makes it difficult or almost impossible to change or
                    cheat the system.{" "}
                  </p>
                  <p>
                    A blockchain is essentially a digital ledger of transactions
                    that is duplicated and distributed across the entire network
                    of computer systems of the blockchain.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item background-questions">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button button-questions collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  <span className="number-button">03</span> How can I buy NFTs?{" "}
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    First, you have to buy cryptocurrency, respectively Ethereum
                    (ETH). Join and fund a cryptocurrency marketplace like
                    “Coinbase” to purchase Ethereum. Get a Crypto-Wallet and
                    sign up for a “non-custodial” wallet like “Metamask”, which
                    is where you can store and control your cryptocurrency and
                    digital assets.
                  </p>
                  <p>
                    Transfer your Ethereum from Coinbase to your wallet (e.g.,
                    Metamask), which gives you the control to purchase NFTs
                    across various marketplaces. Join the NFT Marketplaces
                    “Rarible”: Join Rarible, which is a marketplace for NFT
                    artwork.{" "}
                  </p>{" "}
                  <p>
                    You will be asked to synchronize your digital wallet to the
                    site, which will allow you to purchase and then store your
                    digital assets.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item background-questions">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button button-questions collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  <span className="number-button">04</span> What is utility?{" "}
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    Utility embodies what’s good about NFTs and make things even
                    more attractive for buyers by including exclusive,
                    redeemable benefits that come with owning the NFT.
                  </p>
                </div>
              </div>
            </div>
            <div className="accordion-item background-questions">
              <h2 className="accordion-header" id="headingFive">
                <button
                  className="accordion-button button-questions collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  <span className="number-button">05</span> What can I do with
                  my Certified Veggies NFT?{" "}
                </button>
              </h2>
              <div
                id="collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <p>
                    Owning a Certified Veggie NFT comes with full copyright
                    claims and user sovereignty over the public key it is tied
                    to. What this means is that any Certified Veggie holder has
                    the absolute right to use it as they see fit – from profile
                    pictures to the choice of auctioning it out for sale. A
                    Certified Veggie holder can also stake them and earn
                    passively on them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}
