import React, { useEffect } from "react";
import "./AboutUsMobile.css";
import NftCardsMobile from "./NftCardsMobile";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function AboutUsMobile() {
  // Container that appears after view + children (cards) animated one by one
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.8,
        staggerChildren: 0.6,
      },
    },
  };
  // Cards that appears after view
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  // Animation controls to change from "hidden" to "visible"
  const controls = useAnimation();
  // useInView for react to know when the element is on the screen 
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="AboutUsMobile" id="about-mobile">
      <div className="container-fluid">
        <div className="row"></div>
        <div className="col-12">
          <h2 className="title-about-mobile">About</h2>
          <motion.div
            ref={ref}
            className="container-fluid background-about"
            variants={container}
            initial="hidden"
            animate={controls}
          >
            <div className="row">
              <motion.div
                ref={ref}
                variants={item}
                initial="hidden"
                className="col-12 container-about-text content-wrapper-about "
                animate={controls}
              >
                <h3 className="about-card"> We are first</h3>
                <p className="description-card">
                  First ever NFT project representing Vegetarian / Vegan
                  humans. We have a story / message with our project.
                </p>
              </motion.div>

              <motion.div
                variants={item}
                className="col-12 container-about-text content-wrapper-about "
              >
                <h3 className="about-card"> Rarity </h3>
                <p className="description-card-rarity">
                  Only 365 NFTs - All 1 of 1.
                </p>
              </motion.div>
              <motion.div
                variants={item}
                className="col-12 container-about-text content-wrapper-about "
              >
                <h3 className="about-card"> Quality </h3>
                <p className="description-card">
                  Handcrafted-fully 3D characters - 4K resolution.
                </p>
              </motion.div>
              <motion.div
                variants={item}
                className="col-12 container-about-text content-wrapper-about "
              >
                <h3 className="about-card"> Giving back </h3>
                <p className="description-card">
                  5% of the whole profits made will be donated to charities
                  chosen by the community.
                </p>
              </motion.div>
              <motion.div
                variants={item}
                className="col-12 container-about-text content-wrapper-about "
              >
                <h3 className="about-card"> We are here for you</h3>
                <p className="description-card">
                  Find out more about Metaverse plans in the Roadmaps
                </p>
              </motion.div>
              <motion.div
                variants={item}
                className="col-12 container-about-text content-wrapper-about "
              >
                <h3 className="about-card"> We have fun too</h3>
                <p className="description-card">
                  Events all around the world starting from London. Merch and
                  much more surprises!
                </p>
              </motion.div>
            </div>
          </motion.div>
          <div className="col-6 about-padding">
            <div className="row">
              <div className="col-12" id="roadmap-mobile">
                <NftCardsMobile />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
