import React, { useEffect } from "react";

import "./Roadmap.css";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";

import SvgBackground from "./SvgBackground";

export default function Roadmap() {
  const item = {
    offscreen: {
      y: 300,
    },
    onscreen: {
      y: 0,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.4,
        duration: 0.8,
      },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="Roadmap">
      <SvgBackground />
      <div className="container-fluid margin-roadmap d-none d-lg-block">
        <div className="row">
          <div className="col-12">
            <h2 className="roadmap-title">Roadmap</h2>
          </div>
          <div className="col-12 margin-roadmap-number">
            <h2 className="roadmap-number">02</h2>
          </div>
          <div className="col-12">
            <div className="container-fluid container-cards-roadmap">
              <div className="row">
                <div className="container-fluid stages-cards-margin">
                  <div className="row">
                    <div className="col-5  stage-margin" align="center">
                      <h3 className="stage ">stage 1</h3>
                    </div>
                    <div className="col-1 icon">
                      <FontAwesomeIcon icon={faAnglesRight} />
                    </div>
                    <motion.div
                      ref={ref}
                      variants={item}
                      initial="offscreen"
                      whileInView="onscreen"
                      animate={controls}
                      className="col-6  roadmap-container-one padding-card"
                    >
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ 50 unique and exclusive pieces of art, which are part
                        of the 1st collection.{" "}
                      </h4>
                      <h4 className="description-roadmap">
                        ‣ Our main goal is to make sure your investment and our
                        mint are successful!
                      </h4>
                      <h4 className="description-roadmap">
                        ‣ Immerse yourself in a world where exclusivity and
                        uniqueness can only be bragged about.
                      </h4>
                      <h4 className="description-roadmap">
                        ‣ Be it Vegan, Vegetarian or Art Lover, We will take all
                        365 members to the Metaverse!
                      </h4>
                    </motion.div>
                    <div className="col-5  stage-margin" align="center">
                      <h3 className="stage-two ">stage 2</h3>
                    </div>
                    <div className="col-1 icon">
                      <FontAwesomeIcon icon={faAnglesRight} />
                    </div>
                    <motion.div
                      ref={ref}
                      variants={item}
                      initial="offscreen"
                      whileInView="onscreen"
                      animate={controls}
                      className="col-6  roadmap-container-one padding-card"
                    >
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Veggie-Patch Members will receive benefits like access
                        to major events and getting privileges.{" "}
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Certified Veggies Holders will have lifetime access to
                        events and parties from London to New York to Dubai.
                      </h4>
                      <h4 className="description-roadmap">
                        ‣ Off-Market properties (in terms of real estate) will
                        be part of the benefits.
                      </h4>
                      <h4 className="description-roadmap">
                        ‣ The release date will be announced soon as the time
                        the Veggies are ripe. We encourage you to participate in
                        conversation and connections!{" "}
                      </h4>
                    </motion.div>

                    <div className="col-5  stage-margin" align="center">
                      <h3 className="stage-three ">stage 3</h3>
                    </div>
                    <div className="col-1 icon">
                      <FontAwesomeIcon icon={faAnglesRight} />
                    </div>
                    <motion.div
                      ref={ref}
                      variants={item}
                      initial="offscreen"
                      whileInView="onscreen"
                      animate={controls}
                      className="col-6  roadmap-container-one padding-card"
                    >
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Special Collaborations and Merch will be part of the
                        journey, so no one ever misses out!{" "}
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Our big Veggie heart beats for big things. 5% of all
                        profits will be donated to Cancer charity.{" "}
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Royalties will be invested back to surprises for the
                        community.{" "}
                      </h4>
                    </motion.div>
                    <div className="col-5  stage-margin" align="center">
                      <h3 className="stage-four-roadmap ">stage 4</h3>
                    </div>
                    <div className="col-1 icon">
                      <FontAwesomeIcon icon={faAnglesRight} />
                    </div>
                    <motion.div
                      ref={ref}
                      variants={item}
                      initial="offscreen"
                      whileInView="onscreen"
                      animate={controls}
                      className="col-6  roadmap-container-one padding-card"
                    >
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Once the summit is reached and the mission completed,
                        Veggie-Holders will be rewarded by us with the
                        Certified-Coin.{" "}
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ All Holders will have Full Access to the entire
                        character of their NFT, which can be used on out land in
                        the Metaverse.{" "}
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Veggie-Patch Members can also play to earn Games,
                        which will be launched as well.
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ As soon someone animates an NFT of you or uses it in a
                        movie, you will receive Royalties!
                      </h4>
                      <h4 className="description-roadmap">
                        {" "}
                        ‣ Finally, Roadmap 2 with the New Collection will be
                        released! We are here with a long-term plan.
                      </h4>
                    </motion.div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <h3 className="quote-about">That's not all</h3>
            <h3 className="quote-about-description" id="team">
              Stay tuned for the Roadmap 2 and so on.
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}
