import React from "react";
import "./Landpage.css";
import vid from "./vid.mp4";
import { motion } from "framer-motion";
import ButtonLandpage from "./ButtonLandpage";

export default function Landpage() {
  const line1 = "The";
  const line2 = "Certified";
  const line3 = "Veggies";

  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
        staggerChildren: 0.05,
      },
    },
  };

  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const video = {
    hidden: { opacity: 0, y: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  return (
    <div className="container-fluid landpage-main-container">
      <motion.div
        variants={video}
        initial="hidden"
        animate="visible"
        className="Landpage d-none d-lg-block"
      >
        <div className="container-fluid landpage-margin">
          <div className="row">
            <div className="col-4"></div>
            <motion.div
              variants={video}
              initial="hidden"
              animate="visible"
              className="col-8 d-none d-lg-block video-position"
            >
              <video className="videoTag" autoPlay loop muted>
                <source src={vid} type="video/mp4" />
              </video>
            </motion.div>
            <div className="col-12">
              <motion.div
                variants={sentence}
                initial="hidden"
                animate="visible"
                className="container-fluid title-desktop"
              >
                <div className="row">
                  <div className="col-1"></div>
                  <div className="col-4 headline" align="left">
                    <p>THEY ARE UNIQUE. THEY ARE MADE FOR YOU.</p>
                  </div>
                  <div className="col-7"></div>
                  <div className="col-1"></div>
                  <div className="col-5" align="left">
                    <h2 className="title-font">
                      {line1.split("").map((char, index) => {
                        return (
                          <motion.span
                            key={char + "-" + index}
                            variants={letter}
                          >
                            {char}
                          </motion.span>
                        );
                      })}
                    </h2>
                  </div>
                  <div className="col-6"></div>
                  <div className="col-1"></div>
                  <div className="col-5" align="left">
                    <h1 className="title-font">
                      {line2.split("").map((char, index) => {
                        return (
                          <motion.span
                            key={char + "-" + index}
                            variants={letter}
                          >
                            {char}
                          </motion.span>
                        );
                      })}
                    </h1>
                  </div>
                  <div className="col-6"></div> <div className=""></div>
                  <div className="col-1"></div>
                  <div className="col-5" align="left">
                    <h2 className="title-font">
                      {line3.split("").map((char, index) => {
                        return (
                          <motion.span
                            key={char + "-" + index}
                            variants={letter}
                          >
                            {char}
                          </motion.span>
                        );
                      })}
                    </h2>
                  </div>
                  <div className="col-6"></div>
                  <div className="col-1"></div>
                  <div className="col-4 description" align="left">
                    <h6>
                      The Veggie-Patch that knows neither weakness nor limits.
                      <div>
                        A tribe that endures over time and wants to make you
                        part of it.{" "}
                      </div>
                    </h6>
                  </div>
                  <div className="col-7"></div>
                  <div className="col-1"></div>
                  <div className="col-5" align="left"></div>
                  <div className="col-6"></div>
                </div>
              </motion.div>
            </div>
            <div className="col-4 margin-button-discord">
              <ButtonLandpage />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
