import React from "react";
import "./FooterMobile.css";
import logoIcon from "./logoIcon.png";

export default function FooterMobile() {
  return (
    <div className="container-fluid footer-mobile d-block d-lg-none">
      <div className="row">
        <div className="col-4" align="left">
          <h5 className="footer-certified-mobile ps-4">
            © 2022 Certified Veggies Team | All Rights Reserved
          </h5>
        </div>
        <div className="col-4 padding-footer-mobile" align="center">
          {" "}
          <img
            className=" logo-icon-footer img-fluid"
            src={logoIcon}
            alt="Icon The Certified Veggies"
            width={80}
          />
        </div>
        <div className="col-4" align="right">
          <ul className="link-footer-mobile">
            <li className="nav-item link-footer-mobile">
              <a
                href="https://www.instagram.com/certifiedveggies/"
                target="_blank"
                rel="noreferrer"
                className="link-color"
              >
                Instagram
              </a>
            </li>
            <li className="nav-item link-footer-mobile">
              <a
                href="https://www.instagram.com/certifiedveggies/"
                target="_blank"
                rel="noreferrer"
                className="link-color"
              >
                Twitter
              </a>
            </li>
            <li className="nav-item link-footer-mobile">
              <a
                href="https://www.instagram.com/certifiedveggies/"
                target="_blank"
                rel="noreferrer"
                className="link-color"
              >
                OpenSea
              </a>
            </li>
            <li className="nav-item link-footer-mobile">
              <a
                href="https://www.instagram.com/certifiedveggies/"
                target="_blank"
                rel="noreferrer"
                className="link-color"
              >
                Discord
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
