import React, { useEffect } from "react";
import "./Team.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { HashLink as Link } from "react-router-hash-link";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Team() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const containerThree = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,

      transition: { type: "spring", stiffness: 260, damping: 20, delay: 0.8 },
    },
  };

  const containerTwo = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: "spring", stiffness: 260, damping: 20, delay: 0.5 },
    },
  };

  const containerFour = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { type: "spring", stiffness: 260, damping: 20, delay: 0.1 },
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      initial: { scale: 0 },
      animate: { rotate: 180, scale: 1 },
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="Team">
      <div className="container-fluid team-container">
        <div className="row">
          <div className="col-11">
            <h2 className="team-title">The Team</h2>
            <p className="description-team-title">
              <FontAwesomeIcon icon={faBell} /> (Tap the name to know more)
            </p>
          </div>
          <div className="col-1">
            <h2 className="team-number">03</h2>
          </div>
          <div className="col-6 sepehr-card">
            <motion.div
              ref={ref}
              className="team-container-one padding-nft-team-one"
              variants={container}
              initial="hidden"
              animate={controls}
            >
              <img
                className="img-fluid border-nft-round"
                src={require("./Sepehr.jpg")}
                alt="NFT Sepehr"
                width={550}
              />
              <p>
                <button
                  className="btn btn-team"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExample"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Sepy
                  <div className="description-sepy">
                    {" "}
                    <p> Founder – Marketing – Art director</p>
                  </div>
                </button>
              </p>
              <div className="collapse" id="collapseExample">
                <div className="card card-body">
                  <p>
                    My name is Sepehr, but you can call me Sepy. About a year
                    ago I fell in love with NFT. To me, an NFT is not just a
                    replaceable digital asset, rather it is precious art that
                    deserves appreciation.{" "}
                  </p>
                  <p>
                    As someone who found his way into crypto a long time ago, I
                    decided to give vegans and vegetarians their due respect as
                    the founder of Certified Veggies, which is why this project
                    is a matter of the heart.
                  </p>
                  <p>
                    At the age of 16 I started my modeling career and cooperated
                    with over 150 big brands like Tedbaker, Starbucks etc. At 19
                    I started my own cosmetics brand and am also a luxury real
                    estate agent so I appreciate art, apart from fashion in a
                    different way that is often not seen.
                  </p>
                  <a
                    className="instagram-link"
                    href="https://www.instagram.com/sepy.ak/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find me on Instagram: @sepy.ak
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="col-6 sepehr-card">
            <motion.div
              ref={ref}
              className="team-container-one padding-nft-team-two"
              variants={containerTwo}
              initial="hidden"
              animate={controls}
            >
              <img
                className="img-fluid border-nft-round"
                src={require("./NftPomegranate.jpg")}
                alt="NFT Banana"
                width={550}
              />
              <p>
                <button
                  className="btn btn-team"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExampleOne"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Roxana
                  <div className="description-sepy">
                    {" "}
                    <p>
                      {" "}
                      Founders Right Hand – Deputy Manager - Content Writer
                    </p>
                  </div>
                </button>
              </p>
              <div className="collapse" id="collapseExampleOne">
                <div className="card card-body">
                  <p>
                    My name is Roxana, but you can call me Rox. I am Sepy’s
                    Right Hand, Clinical Psychologist and Psychotherapist-to-be,
                    and I graduated from school with a degree in Literature in
                    four different languages, a form of art. This keyword has
                    been with me all my life, be it in the form of literature,
                    visual arts, fashion, photography or music.
                  </p>{" "}
                  <p>
                    My love and appreciation for the arts, and my respect for
                    Sepy are the reason for my involvement in this project.
                    Accordingly, it is not just any project, but also an affair
                    of the heart, which I do with much skill.
                  </p>
                  <a
                    href="https://www.instagram.com/whoisraziel/"
                    className="instagram-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find me on Instagram: @whoisraziel
                  </a>
                </div>
              </div>
            </motion.div>
          </div>
          <div className="col-6 sepehr-card">
            <motion.div
              className="team-container-one padding-nft-team-three"
              variants={containerThree}
              initial="hidden"
              animate={controls}
            >
              <img
                className="img-fluid border-nft-round"
                src={require("./NftArtist.jpg")}
                alt="NFT Pear"
                width={550}
              />
              <div>
                <Link smooth to="/artist#artist">
                  <button
                    className="btn btn-team"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseExampleOne"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                  >
                    Huseyin
                    <div className="description-sepy">
                      {" "}
                      <p> Artist</p>
                    </div>
                  </button>
                </Link>
              </div>
            </motion.div>
          </div>
          <motion.div
            className="col-6 sepehr-card"
            variants={containerFour}
            initial="hidden"
            animate={controls}
          >
            <div className="team-container-one padding-nft-team-four">
              <img
                className="img-fluid border-nft-round"
                src={require("./NftWatermelon.jpg")}
                alt="NFT Watermelon"
                width={550}
              />
              <p>
                <button
                  className="btn btn-team"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseExampleFour"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  Mili
                  <div className="description-sepy">
                    {" "}
                    <p> Web Developer - UI designer</p>
                  </div>
                </button>
              </p>
              <div className="collapse" id="collapseExampleFour">
                <div className="card card-body">
                  Hello!{" "}
                  <p>
                    I'm Mili, based in beautiful Florida in United States. I'm a
                    certified React / Front-End Developer from SheCodes.{" "}
                  </p>
                  I have been working in the industry for a year now! I'm also a
                  Content Creator / Influencer on Instagram as @mili.codes an d
                  Design Partner of Spline, a 3D collaborative tool for Web
                  Design. By showcasing the best coding and organizational tips
                  , I engage daily with developers in the tech community.
                  <p>
                    {" "}
                    I also have a background in Digital Illustration, which
                    inspires me to create UI designs alongside my work.
                  </p>{" "}
                  <p>
                    Being part of this project has been a incredible opportunity
                    to grow and bein g part of a great team alongside Sepy's
                    vision for the future, I truly believe in the greatness of
                    it with all my skill.
                  </p>
                  <a
                    href="https://www.instagram.com/mili.codes/"
                    className="instagram-link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find me on Instagram: @mili.codes
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
