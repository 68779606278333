import React from "react";
import GifLogoMobile from "./GifLogoMobile.gif";
import "./CoreTeamMobile.css";

export default function CoreTeamMobile() {
  return (
    <div className="CoreTeamMobile">
      <div className="container-fluid">
        <div className="row border-coreteam">
          <div className="col-12 padding-title-coreteam">
            <h2 className="roadmap-title-mobile" align="center">
              Core Team
            </h2>
            <h5 className="description-coreteam-mobile" align="center">
              Certified Veggies Collaborators
            </h5>
          </div>
        </div>
        <div className="row ">
          <div
            className="col-12 border-coreteam  section-coreteam-mobile "
            align="left"
          >
            <div className="row">
              <div className="col-2 coreteam-item">
                <p>001</p>
              </div>
              <div className="col-3 coreteam-item">
                <p>Name</p>
              </div>
              <div className="col-4 coreteam-item">
                <p>Section</p>
              </div>
              <div className="col-3 coreteam-item" align="right">
                <p>Country</p>
              </div>
            </div>
          </div>
          <div
            className="col-12 border-coreteam-next  section-coreteam-next-mobile"
            align="left"
          >
            <div className="row">
              <div className="col-2 coreteam-item">
                <p>002</p>
              </div>
              <div className="col-3 coreteam-item">
                <p>Name</p>
              </div>
              <div className="col-4 coreteam-item">
                <p>Section</p>
              </div>
              <div className="col-3 coreteam-item" align="right">
                <p>Country</p>
              </div>
            </div>
          </div>
          <div
            className="col-12 border-coreteam-next  section-coreteam-next-mobile"
            align="left"
          >
            <div className="row">
              <div className="col-2 coreteam-item">
                <p>003</p>
              </div>
              <div className="col-3 coreteam-item">
                <p>Name</p>
              </div>
              <div className="col-4 coreteam-item">
                <p>Section</p>
              </div>
              <div className="col-3 coreteam-item" align="right">
                <p>Country</p>
              </div>
            </div>
          </div>
        </div>
        <img className="margin-logo-mobile" src={GifLogoMobile} alt="Certified Veggies Logo" width={300} />
      </div>
    </div>
  );
}
