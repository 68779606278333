import React from "react";
import "./DiscordMobile.css";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonDiscord from "./ButtonDiscord";
import ButtonD from "./ButtonD";
export default function Discord() {
  return (
    <div className="Discord container-fluid">
      <div className="row">
        <div className="col-12">
          <h2 className="crypto-veggies-mobile">
            Join the Crypto Veggies Society
          </h2>
         <ButtonD />
        </div>
      </div>
    </div>
  );
}
