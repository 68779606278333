import React, { useEffect } from "react";
import "./Artist.css";
import NftCardsArtist from "./NftCardsArtist";
import ArtistVid from "./ArtistVid.MP4";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Artist() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      initial: { scale: 0 },
      animate: { rotate: 180, scale: 1 },
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="Artist d-none d-lg-block" id="artist">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6">
            <h2 className="artist-title">The Artist</h2>
            <h4 className="artist-name">Huseyin</h4>
          </div>
          <div className="col-5">
            <motion.div
              ref={ref}
              variants={container}
              initial="hidden"
              animate={controls}
              className="artist-container padding-nft-artist"
            >
              <img
                className="img-fluid border-nft-round"
                src={require("./NftArtist.jpg")}
                alt="NFT Pear"
                width={600}
              />
            </motion.div>
          </div>
          <NftCardsArtist />
          <div
            className="col-4 margin-description artist-description"
            align="left"
          >
            <p>
              Huseyin is a digital artist and the one behind the screen. Art has
              always been a way for him to express himself, which is why he
              wanted to create expressive, thoughtful and unique art here as
              well.{" "}
            </p>{" "}
            <p>
              {" "}
              He worked with institutions such as UNESCO and many unique
              celebrities like Sami Yusuf, who has received two Oscars for his
              movies, and Ayisha Abdul Basith, who is a famous musician.
            </p>
            <p>
              {" "}
              He also worked in several Russian studios for Playstation Games,
              as a level designer, UX UI designer and concept designer.
            </p>
            <div className="col-12 instagram-link-artist" align="center">
              <a
                href="https://www.instagram.com/kuchkarov_/"
                align="center"
                target="_blank"
                rel="noreferrer"
              >
                Find me on Instagram: @kuchkarov_
              </a>
            </div>
          </div>
          <div className="col-12 artist-project-title">
            <h2 className="artist-title-two">Know more about me</h2>
          </div>
          <motion.div
            className="row artist-project"
            variants={item}
            initial="hidden"
            animate={controls}
          >
            <div className="col-6 artist-project-video">
              <video
                className="videoTag-artist"
                autoPlay
                loop
                muted
                width={700}
              >
                <source src={ArtistVid} type="video/mp4" />
              </video>
            </div>
            <div className="col-5 description-video-artist">
              {" "}
              <p>
                {" "}
                Worked with the world Composer as "Sami Yusuf". We had several
                projects, and several design albums and playlists.
              </p>{" "}
              <p>
                One of the playlists was attended by A. Rahman, who received 2
                times an Oscar for his films. Mostly used to work as a cover
                designer for musicians.
              </p>
            </div>
          </motion.div>
          <motion.div
            ref={ref}
            className="row"
            variants={item}
            initial="hidden"
            animate={controls}
          >
            <div className="col-12 artist-project-title">
              <h2 className="artist-title-four">My Past Projects</h2>
            </div>
            <div className="col-6 description-project-artist" align="left">
              <p>
                Including working with UNESCO (one of the sessions of the UNESCO
                World Heritage Committee), unfortunately I will not be able to
                disclose which of the projects. I work in several Russian
                studios for PS games, as a Level designer, UX UI designer,
                Concept Designer and I am responsible for optimizing the game.
                At the moment, 2 Steam games have been released.
              </p>{" "}
              <p>
                One of the playlists was attended by A. Rahman, who received 2
                times an Oscar for his films. Mostly used to work as a cover
                designer for musicians.
              </p>
              <div className="row">
                <div className="col-6 artwork-padding" align="center">
                  <img
                    className="img-fluid border-projects-round"
                    src={require("./ArtistTwo.jpg")}
                    alt="Huseyin Artwork"
                    width={800}
                  />
                </div>
                <div className="col-6 artwork-padding" align="center">
                  <img
                    className="img-fluid border-projects-round"
                    src={require("./ArtistThree.jpg")}
                    alt="Huseyin Artwork"
                    width={800}
                  />
                </div>
                <div className="col-12 artwork-padding" align="center">
                  <img
                    className="img-fluid border-projects-round"
                    src={require("./ArtistFour.jpg")}
                    alt="Huseyin Artwork"
                    width={700}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-12">
                  <img
                    className="img-fluid border-nft-round"
                    src={require("./ArtistOne.jpg")}
                    alt="Huseyin Artwork"
                    width={550}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <h2 className="artist-title-three">If is important</h2>
            </div>
            <div className="col-6">
              <h5 className="about-three">
                {" "}
                He took first place among Graduate Students, Masters, Bachelors
                (The best project of the year in Moscow) as the development of
                methods for teaching students and optimizing the productivity of
                information perception of children and students.
              </h5>
            </div>
            <div className="col-6 about-two">
              <h5 className="line-height">
                {" "}
                Creating an AI. He has won several awards for projects from the
                Governor. There are several certificates, including a
                certificate from Intel (according to the methodology of the
                pedagogical direction).
              </h5>
            </div>
            <div className="col-6 ">
              <h5 className=" about-two line-height">
                {" "}
                Now we are planning to open an exhibition of our works in one of
                the states of America (at the moment I can’t say for sure,
                because due to conflicts with Ukraine they may be transferred to
                another state).
              </h5>
            </div>
            <div className="col-6 about-three">
              <h5 className="line-height">
                {" "}
                More scientific achievements than creative ones. We are also
                working with an animation studio , for one Islamic cartoon :
                "About Adam".
              </h5>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
