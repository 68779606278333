import React from "react";
import "./Navigation.css";
import logoIcon from "./logoIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { HashLink as Link } from "react-router-hash-link";

export default function Navigation() {
  return (
    <div className="Navigation">
      <nav className="navbar navbar-expand-lg navigation-links fixed-top navbar-dark nav-bar-color">
        <div className="container-fluid">
          {/*Navigation logo*/}
          <div className="col-lg-2">
            <a className="navbar-brand" href="/">
              <img
                className="img-fluid"
                src={logoIcon}
                alt="Icon The Certified Veggies"
                width={55}
              />
            </a>
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            {/*Navigation links*/}
            <div className="col-lg-10">
              <ul className="navbar-nav d-flex justify-content-center">
                <li className="nav-item d-none d-lg-block">
                  <Link className="nav-link" to="/home#landpage">
                    HOME
                  </Link>
                </li>
                <li className="nav-item d-block d-lg-none">
                  <Link className="nav-link" to="/home#landpage-mobile">
                    HOME
                  </Link>
                </li>
                <li className="nav-item d-none d-lg-block">
                  <Link className="nav-link" smooth to="/home#about">
                    ABOUT
                  </Link>
                </li>
                <li className="nav-item d-block d-lg-none">
                  <Link className="nav-link" smooth to="/home#about-mobile">
                    ABOUT
                  </Link>
                </li>
                <li className="nav-item d-none d-lg-block">
                  <Link className="nav-link" smooth to="/home#roadmap">
                    ROADMAP
                  </Link>
                </li>
                <li className="nav-item d-block d-lg-none">
                  <Link className="nav-link" smooth to="/home#roadmap-mobile">
                    ROADMAP
                  </Link>
                </li>
                <li className="nav-item d-none d-lg-block">
                  <Link className="nav-link" smooth to="/home#team">
                    TEAM
                  </Link>
                </li>
                <li className="nav-item d-block d-lg-none">
                  <Link className="nav-link" smooth to="/home#team-mobile">
                    TEAM
                  </Link>
                </li>
                <li className="nav-item d-none d-lg-block">
                  <Link className="nav-link" smooth to="/home#faq">
                    FAQ
                  </Link>
                </li>
                <li className="nav-item d-block d-lg-none">
                  <Link className="nav-link" smooth to="/home#faq-mobile">
                    FAQ
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <div className="row">
                <div className="col-4">
                  <a
                    href="https://www.instagram.com/certifiedveggies/"
                    rel="non-referrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      className="icon-header"
                    />
                  </a>
                </div>

                <div className="col-4">
                  <a
                    href="https://www.twitter.com/theveggietalks"
                    rel="non-referrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faTwitter} className="icon-header" />
                  </a>
                </div>
                <div className="col-4">
                  <a
                    href="https://www.instagram.com/certifiedveggies/"
                    rel="non-referrer"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faDiscord} className="icon-header" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
