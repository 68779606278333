import React, { useEffect } from "react";
import "./ArtistMobile.css";
import NftCardsA from "./NftCardsA";
import ArtistVid from "./ArtistVid.MP4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function ArtistMobile() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.3,
      },
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      initial: { scale: 0 },
      animate: { rotate: 180, scale: 1 },
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <div className="ArtistMobile d-block d-lg-none" id="artist">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="artist-title">The Artist</h2>
            <h4 className="artist-name">Huseyin</h4>
          </div>
          <motion.div
            className="col-12"
            ref={ref}
            variants={container}
            initial="hidden"
            animate={controls}
          >
            <div className="artist-container-mobile padding-nft-artist-mobile">
              <img
                className="img-fluid border-nft-round"
                src={require("./NftArtist.jpg")}
                alt="NFT Pear"
                width={550}
              />
            </div>
          </motion.div>
          <div
            className="col-12 margin-description-mobile artist-description-mobile"
            align="center"
          >
            <p>
              Hoseyin is a digital artist and the one behind the screen. Art has
              always been a way for him to express himself, which is why he
              wanted to create expressive, thoughtful and unique art here as
              well.{" "}
            </p>{" "}
            <p>
              {" "}
              He worked with institutions such as UNESCO and many unique
              celebrities like Sami Yusuf, who has received two Oscars for his
              movies, and Ayisha Abdul Basith, who is a famous musician.
            </p>
            <p>
              {" "}
              He also worked in several Russian studios for Playstation Games,
              as a level designer, UX UI designer and concept designer
            </p>
          </div>
        </div>
        <NftCardsA />
        <div className="col-12 artist-project-title">
          <h2 className="artist-title-two-mobile">Know more about me</h2>
        </div>
        <div className="row artist-project">
          <div className="col-12 artist-project-video">
            <video
              className="videoTag-artist"
              autoPlay
              loop
              muted
              playsInline
              width={360}
            >
              <source src={ArtistVid} type="video/mp4" />
            </video>
          </div>
          <div className="col-12 description-video-artist-mobile">
            {" "}
            <p>
              {" "}
              Worked with the world Composer as "Sami Yusuf". We had several
              projects, and several design albums and playlists.
            </p>{" "}
            <p>
              One of the playlists was attended by A. Rahman, who received 2
              times an Oscar for his films. Mostly used to work as a cover
              designer for musicians.
            </p>
          </div>
        </div>
        <motion.div
          className="row"
          variants={item}
          initial="hidden"
          animate={controls}
        >
          <div className="col-12 artist-project-title">
            <h2 className="artist-title-four-mobile">My Past Projects</h2>
          </div>
          <div className="col-6 description-project-artist-mobile" align="left">
            <p>
              Including working with UNESCO (one of the sessions of the UNESCO
              World Heritage Committee), unfortunately I will not be able to
              disclose which of the projects.
            </p>
          </div>
          <div className="col-6">
            <img
              className="img-fluid border-nft-round"
              src={require("./ArtistOne.jpg")}
              alt="Huseyin Artwork"
              width={550}
            />
          </div>
          <div className="col-12 description-project-artist-mobile">
            <p>
              I work in several Russian studios for PS games, as a Level
              designer, UX UI designer, Concept Designer and I am responsible
              for optimizing the game. At the moment, 2 Steam games have been
              released.
            </p>{" "}
            <p>
              One of the playlists was attended by A. Rahman, who received 2
              times an Oscar for his films. Mostly used to work as a cover
              designer for musicians.
            </p>
          </div>
          <div className="row">
            <div className="col-6 artwork-padding" align="center">
              <img
                className="img-fluid border-projects-round"
                src={require("./ArtistTwo.jpg")}
                alt="Huseyin Artwork"
                width={800}
              />
            </div>
            <div className="col-6 artwork-padding" align="center">
              <img
                className="img-fluid border-projects-round"
                src={require("./ArtistThree.jpg")}
                alt="Huseyin Artwork"
                width={800}
              />
            </div>
            <div className="col-12 artwork-padding" align="center">
              <img
                className="img-fluid border-projects-round"
                src={require("./ArtistFour.jpg")}
                alt="Huseyin Artwork"
                width={700}
              />
            </div>
          </div>
        </motion.div>
        <div className="col-12">
          <h2 className="artist-title-three-mobile">If is important</h2>
        </div>
        <div className="col-12">
          <a
            className="btn about-mobile-artist-important"
            data-bs-toggle="collapse"
            href="#collapseExample"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Best Projects of the Year (Moscow){" "}
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </a>
          <h5 className="about-mobile-artist collapse" id="collapseExample">
            {" "}
            He took first place among Graduate Students, Masters, Bachelors (The
            best project of the year in Moscow) as the development of methods
            for teaching students and optimizing the productivity of information
            perception of children and students.
          </h5>
        </div>
        <div className="col-12">
          <a
            className="btn about-mobile-artist-important"
            data-bs-toggle="collapse"
            href="#collapseExampleOne"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Created AI and won several awards{" "}
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </a>
          <h5
            className=" line-height about-mobile-artist collapse"
            id="collapseExampleOne"
          >
            {" "}
            Creating an AI. He has won several awards for projects from the
            Governor. There are several certificates, including a certificate
            from Intel (according to the methodology of the pedagogical
            direction).
          </h5>
        </div>
        <div className="col-12 ">
          <a
            className="btn about-mobile-artist-important"
            data-bs-toggle="collapse"
            href="#collapseExampleTwo"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <span className="padding-button-artist">
              Opening an exhibition{" "}
            </span>
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </a>
          <h5 className="about-mobile-artist collapse" id="collapseExampleTwo">
            {" "}
            Now we are planning to open an exhibition of our works in one of the
            states of America (at the moment I can’t say for sure, because due
            to conflicts with Ukraine they may be transferred to another state).
          </h5>
        </div>
        <div className="col-12">
          <a
            className="btn about-mobile-artist-important"
            data-bs-toggle="collapse"
            href="#collapseExampleThree"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            Working with an animation studio{" "}
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </a>
          <h5
            className="about-mobile-artist  line-height collapse"
            id="collapseExampleThree"
          >
            {" "}
            More scientific achievements than creative ones. We are also working
            with an animation studio , for one Islamic cartoon : "About Adam".
          </h5>
        </div>
      </div>
      <div className="col-12 margin-artist-instagram">
        <a
          className="col-12 instagram-link-artist"
          href="https://www.instagram.com/kuchkarov_/"
          align="center"
          target="_blank"
          rel="noreferrer"
        >
          Find me on Instagram: @kuchkarov_
        </a>
      </div>
    </div>
  );
}
