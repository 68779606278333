import React from "react";
import { motion } from "framer-motion";
import "./LandpageMobile.css";
import Giftvid from "./Giftvid.gif";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonD from "./ButtonD";

export default function LandpageMobile() {
  const line1 = "The";
  const line2 = "Certified";
  const line3 = "Veggies";

  const sentence = {
    hidden: { opacity: 1 },
    visible: {
      opacity: 1,
      transition: {
        delay: 1,
        staggerChildren: 0.05,
      },
    },
  };

  const letter = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const video = {
    hidden: { opacity: 0, y: 0 },
    visible: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };
  return (
    <div className="LandpageMobile">
      <div className="Landpage d-block d-none-block">
        <div className="container landpage-margin">
          <div className="row">
            <div className="col-12 headline-mobile" align="left">
              THEY ARE UNIQUE. <div>THEY ARE MADE FOR YOU.</div>
            </div>
            <div className="col-12">
              <motion.div
                variants={sentence}
                initial="hidden"
                animate="visible"
                className="container title-desktop-mobile"
              >
                <div className="row">
                  <div className="col-12 title-font-mobile" align="center">
                    {line1.split("").map((char, index) => {
                      return (
                        <motion.span key={char + "-" + index} variants={letter}>
                          {char}
                        </motion.span>
                      );
                    })}
                  </div>

                  <div className="col-12 title-font-mobile" align="center">
                    {line2.split("").map((char, index) => {
                      return (
                        <motion.span key={char + "-" + index} variants={letter}>
                          {char}
                        </motion.span>
                      );
                    })}
                  </div>

                  <div className="col-12 title-font-mobile" align="center">
                    {line3.split("").map((char, index) => {
                      return (
                        <motion.span key={char + "-" + index} variants={letter}>
                          {char}
                        </motion.span>
                      );
                    })}
                  </div>
                </div>
              </motion.div>
            </div>
            <motion.div
              variants={video}
              initial="hidden"
              animate="visible"
              className="col-12 d-block d-lg-none"
            >
              <img src={Giftvid} alt="NFT Carrot" width={340} />
            </motion.div>
            <div className="col-12 description-mobile" align="center">
              The Veggie-Patch that knows neither weakness nor limits.
              <div>
                A tribe that endures over time and wants to make you part of it.{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12" align="center">
        <ButtonD />
      </div>
    </div>
  );
}
