import React from "react";
import "./App.css";
import Navigation from "./Navigation";
import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import Artist from "./Artist";
import ArtistMobile from "./ArtistMobile";
import Footer from "./Footer";
import FooterMobile from "./FooterMobile";

function App() {
  return (
    <div className="App">
      <header className="App-header " id="landpage">
        <div className="container-fluid fixed-top">
          <div className="row margin-header">
            {/*Navigation for desktop*/}
            <Navigation />
          </div>
        </div>
      </header>
      <main>
        <Routes>  
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/artist" element={<Artist />} />
          <Route path="/artistmobile" element={<ArtistMobile />} />
        </Routes>
      </main>
      <footer>
        <div className="container-fluid">
          <div className="row margin-footer">
            {/*Navigation for desktop*/}
            <Footer />
            <FooterMobile />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
