import React from "react";
import Landpage from "./Landpage";
import LandpageMobile from "./LandpageMobile";
import AboutUs from "./AboutUs";
import AboutUsMobile from "./AboutUsMobile";
import Roadmap from "./Roadmap";
import RoadmapMobile from "./RoadmapMobile";
import Team from "./Team";
import TeamMobile from "./TeamMobile";
import CoreTeam from "./CoreTeam";
import CoreTeamMobile from "./CoreTeamMobile";
import Questions from "./Questions";
import QuestionsMobile from "./QuestionsMobile";
import Discord from "./Discord";
import DiscordMobile from "./DiscordMobile";
import RoadmapMedium from "./RoadmapMedium";

export default function Home() {
  return (
    <div className="Home">
      {/*Desktop Landpage*/}
      <div className="col-12">
        <Landpage />
      </div>
      {/*Desktop Mobile*/}
      <div className="container-fluid d-block d-lg-none">
        <div className="row">
          <LandpageMobile />
        </div>
      </div>
      <div className="container-fluid about-us-padding d-none d-lg-block">
        <div className="row">
          <AboutUs />
          <Roadmap />
          <Team />
          <CoreTeam />
          <Questions />
          <Discord />
        </div>
      </div>
      <div className="container-fluid d-block d-lg-none">
        <div className="row">
          <AboutUsMobile />
          <RoadmapMobile />
          <RoadmapMedium />
          <TeamMobile />
          <CoreTeamMobile />
          <QuestionsMobile />
          <DiscordMobile />
        </div>
      </div>
    </div>
  );
}
