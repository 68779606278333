import React, { useEffect } from "react";
import "./NftCardsAM.css";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function NftCardsA() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.3,
      },
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      initial: { scale: 0 },
      animate: { rotate: 180, scale: 1 },
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <motion.div
      className="NftCardsA"
      ref={ref}
      variants={item}
      initial="hidden"
      animate={controls}
    >
      <div className="container">
        <div className="row" align="center">
          <div className="col-1"></div>
          <div className="col-5 nft-container-one-artist-mobile padding-nft-artist-card-mobile">
            <img
              className="img-fluid  border-nft-round"
              src={require("./NftFour.jpg")}
              alt="NFT Strawbery"
              width={270}
            />
          </div>
          <div className="col-5 nft-container-two-artist-mobile padding-nft-artist-card-mobile">
            <img
              className="img-fluid border-nft-round"
              src={require("./NftThree.png")}
              alt="NFT Potato"
              width={270}
            />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
