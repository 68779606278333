import React, { useEffect } from "react";
import "./AboutUs.css";
import NftCards from "./NftCards";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function AboutUs() {
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.3,
      },
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      initial: { scale: 0 },
      animate: { rotate: 180, scale: 1 },
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="AboutUs" id="about">
      <div className="container-fluid">
        <div className="row">
          <div className="col-6 about-padding border-about">
            <h2 className="title-about">About</h2>
            <motion.div
              ref={ref}
              className="container-fluid background-about"
              variants={container}
              initial="hidden"
              animate={controls}
            >
              <div className="row">
                <motion.div
                  variants={item}
                  className="col-4 container-about-text content-wrapper-about "
                >
                  <h3 className="about-card"> We are first</h3>
                  <p className="description-card">
                    First ever NFT project representing Vegetarian / Vegan
                    humans. We have a story / message with Our project.
                  </p>
                </motion.div>

                <motion.div
                  variants={item}
                  className="col-4 container-about-text content-wrapper-about "
                >
                  <h3 className="about-card"> Rarity </h3>
                  <p className="description-card-rarity">
                    Only 365 NFTs - All 1 of 1.
                  </p>
                </motion.div>
                <motion.div
                  variants={item}
                  className="col-4 container-about-text content-wrapper-about "
                >
                  <h3 className="about-card"> Quality </h3>
                  <p className="description-card">
                    Handcrafted-fully 3D characters - 4K resolution.
                  </p>
                </motion.div>
                <motion.div
                  variants={item}
                  className="col-4 container-about-text content-wrapper-about "
                >
                  <h3 className="about-card"> Giving back </h3>
                  <p className="description-card">
                    5% of the whole profits made will be donated to charities
                    chosen by the community.
                  </p>
                </motion.div>
                <motion.div
                  variants={item}
                  className="col-4 container-about-text content-wrapper-about "
                >
                  <h3 className="about-card"> We are here for you</h3>
                  <p className="description-card">
                    Find out more about Metaverse plans in the Roadmaps
                  </p>
                </motion.div>
                <motion.div
                  variants={item}
                  className="col-4 container-about-text content-wrapper-about "
                >
                  <h3 className="about-card"> We have fun too</h3>
                  <p className="description-card">
                    Events all around the world starting from London. Merch
                    and much more surprises!
                  </p>
                </motion.div>
              </div>
            </motion.div>
          </div>
          <div className="col-6 about-padding border-about-number">
            <h4 className="number-one">01</h4>
            <div className="row">
              <div className="col-12">
                <NftCards />
              </div>
            </div>
          </div>
          <div className="col-6 about-padding" id="roadmap">
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}
