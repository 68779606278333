import React from "react";
import "./Discord.css";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonDiscord from "./ButtonDiscord";
export default function Discord() {
  return (
    <div className="Discord container-fluid">
      
      <div className="row">
        <div className="col-12">
          <h2 className="crypto-veggies">Join the Crypto Veggies Society</h2>
        </div>
        <ButtonDiscord />
      </div>
    </div>
  );
}
