import React, { useEffect } from "react";
import "./NftCardsMobile.css";
import { motion } from "framer-motion";
import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function NftCardsMobile() {
  const container = {
    hidden: { opacity: 0, scale: 1 },
    visible: {
      x: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const nft = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: 10,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const nftTwo = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };

  const nftThree = {
    hidden: { x: 20, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      rotate: -10,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
      },
    },
  };
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    }
  }, [controls, inView]);
  return (
    <div className="NftCardsMobile">
      <motion.div
        ref={ref}
        variants={container}
        initial="hidden"
        animate={controls}
        className="container"
        align="center"
      >
        <div className="row">
          <div className="col-12"></div>
          <motion.div className="col-12 nft-container-one-mobile padding-nft-mobile">
            <img
              className="img-fluid border-nft-round"
              src={require("./NftOne.jpg")}
              alt="NFT Banana"
              width={300}
            />
          </motion.div>
          <motion.div className="col-12 nft-container-two-mobile padding-nft-mobile">
            <img
              className="img-fluid border-nft-round"
              src={require("./NftTwo.png")}
              alt="NFT blackberry"
              width={300}
            />
          </motion.div>
          <motion.div className="col-12 nft-container-three-mobile padding-nft-mobile">
            <img
              className="img-fluid border-nft-round"
              src={require("./NftThree.png")}
              alt="NFT Strawberry"
              width={300}
            />
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
